<template>
    <div class="form_container">
        <form @submit.prevent="formularioRegistroInicial" action="http://localhost:3000/formulario-registros-iniciales" method="post" novalidate enctype="multipart/form-data">
            <a href="declaracion-jurada.pdf" download style="font-size: 15px; color: dodgerblue;" >CONSULTE NUESTRA POLÍTICA DE PRIVACIDAD</a> <br> <br>
              <a href="terminos-condiciones.pdf" download style="font-size: 15px; color: dodgerblue;">COSULTE AVISO LEGAL - TERMINOS DE USO</a>
              <br><br>
            <label for="fecha">FECHA DE HOY (REQUERIDO):</label>
            <input v-model="formDataFormularioRegistrosIniciales.fecha" type="date" id="fecha" name="fecha" required>
            <div v-if="errorsRegistrosIniciales.fecha" class="error-message">
                <ul>
                    <li v-for="error in errorsRegistrosIniciales.fecha">{{ error }}</li>
                </ul>
            </div>
            <br><br>
                <label for="nombre-ejemplar">NOMBRE DEL EJEMPLAR (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.nombre_ejemplar" type="text" id="nombre-ejemplar" name="nombre-ejemplar" required>
                <div v-if="errorsRegistrosIniciales.nombre_ejemplar" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.nombre_ejemplar">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="fecha">FECHA DE NACIMIENTO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.fecha_nacimiento" type="date" id="fecha-nacimiento" name="fecha" required>
                <div v-if="errorsRegistrosIniciales.fecha_nacimiento" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.fecha_nacimiento">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="raza">RAZA (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.raza" type="text" id="raza" name="raza" required> 
                <div v-if="errorsRegistrosIniciales.raza" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="variedad-raza">VARIEDAD DE RAZA - <i>si no hubiere una variedad, completar con "Sin Variedad" </i>- (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.variedad_raza" type="text" id="variedad-raza" name="variedad-raza" required> 
                <div v-if="errorsRegistrosIniciales.variedad_raza" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.variedad_raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="color">COLOR (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.color" type="text" id="color" name="color" required>
                <div v-if="errorsRegistrosIniciales.color" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.color">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pelo">TIPO DE PELO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.pelo" type="text" id="pelo" name="pelo" required>
                <div v-if="errorsRegistrosIniciales.pelo" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.pelo">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="opciones">SEXO (REQUERIDO)</label>
                <select v-model="formDataFormularioRegistrosIniciales.sexo" id="sexo" name="opciones" required>
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Macho">Macho</option>
                    <option value="Hembra">Hembra</option>
                </select>
                <div v-if="errorsRegistrosIniciales.sexo" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.sexo">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="opciones">¿CÓMO OBTUVO EL PERRO? (REQUERIDO)</label>
                <select v-model="formDataFormularioRegistrosIniciales.como_obtuvo_perro" id="como-obtuvo-perro" name="opciones" required>
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Compra">Compra</option>
                    <option value="Regalo">Regalo</option>
                    <option value="Adopción">Adopción</option>
                    <option value="Rescatado de la vía pública">Rescatado de la vía pública</option>
                    <option value="Otro">Otro</option>
                </select>
                <div v-if="errorsRegistrosIniciales.como_obtuvo_perro" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.como_obtuvo_perro">{{ error }}</li>
                    </ul>
                </div>
                    <br><br><br>
            <h2>DATOS DEL PROPIETARIO</h2>
                <label for="nombre">NOMBRE DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.nombre" type="text" id="nombre" name="nombre" required>
                <div v-if="errorsRegistrosIniciales.nombre" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.nombre">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.apellido" type="text" id="apellido" name="apellido" required> 
                <div v-if="errorsRegistrosIniciales.apellido" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.apellido">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="documento">NÚMERO DE DOCUMENTO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.documento" type="text" id="documento" name="documento" required>
                <div v-if="errorsRegistrosIniciales.documento" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.documento">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="telefono">TELÉFONO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.celular" type="tel" id="telefono" name="telefono" required>
                <div v-if="errorsRegistrosIniciales.celular" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.celular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.email" type="text" id="email" name="email" @input="validarEmail" required>
                <div v-if="errorsRegistrosIniciales.email" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.email">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="domicilio">DOMICILIO (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.domicilio" type="text" id="domicilio" name="domicilio" required>
                <div v-if="errorsRegistrosIniciales.domicilio" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.domicilio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="codigo-postal">CÓDIGO POSTAL (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.codigo_postal" type="text" id="codigo-postal" name="codigo-postal" required>
                <div v-if="errorsRegistrosIniciales.codigo_postal" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.codigo_postal">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD / LOCALIDAD (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.ciudad" type="text" id="ciudad" name="ciudad" required>
                <div v-if="errorsRegistrosIniciales.ciudad" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.ciudad">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.provincia" type="text" id="provincia" name="provincia" required>
                <div v-if="errorsRegistrosIniciales.provincia" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.provincia">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pais">PAIS (REQUERIDO):</label>
                <input v-model="formDataFormularioRegistrosIniciales.pais" type="text" id="pais" name="pais" required>
                <div v-if="errorsRegistrosIniciales.pais" class="error-message">
                    <ul>
                        <li v-for="error in errorsRegistrosIniciales.pais">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                
                <label class="archivo" for="archivo">Adjuntar FRENTE de Documento de Identidad (REQUERIDO):</label>
                <input type="file" id="foto-documento" name="archivo1" multiple @change="handleFileChange($event, 'archivo1')" required>
                <div v-if="errorsRegistrosIniciales.archivo1" class="error-message">
                    <ul>
                    <li v-for="error in errorsRegistrosIniciales.archivo1">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label class="archivo" for="archivo">Adjuntar Foto del Perro - <i>Puede subir más de una imagen, si así lo requiere</i> (REQUERIDO):</label>
                <input type="file" id="foto-perro" name="archivo2" multiple @change="handleFileChange($event, 'archivo2')" required>
                <div v-if="errorsRegistrosIniciales.archivo2" class="error-message">
                    <ul>
                    <li v-for="error in errorsRegistrosIniciales.archivo2">{{ error }}</li>
                    </ul>
                </div>
                <br>
        
                <h2>DATOS DEL 2° TITULAR</h2> 
                <label for="mostrarForm">¿HAY 2° TITULAR?</label>
                <select v-model="formDataFormularioRegistrosIniciales.mostrarForm" id="mostrarForm" name="mostrarForm" required>
                    <option value=Si>Sí</option>
                    <option value=No>No</option>
                </select>
                
                <div v-if="formDataFormularioRegistrosIniciales.mostrarForm === 'Si'" class="if_container">
                    <label for="opciones">TIPO DE FIRMA (REQUERIDO)</label>
                    <select v-model="formDataFormularioRegistrosIniciales.section" id="opciones" name="opciones" required>
                        <option value="" disabled selected>--Por favor, elige una opción--</option>
                        <option value="Indistinta">Indistinta</option>
                        <option value="Conjunta">Conjunta</option>
                    </select>
                    <div v-if="errorsRegistrosIniciales.section" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.section">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="nombre-titular2">NOMBRE DEL 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioRegistrosIniciales.nombre_titular2" type="text" id="nombre-titular2" name="nombre" required> 
                    <div v-if="errorsRegistrosIniciales.nombre_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.nombre_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="apellido-titular2">APELLIDO DEL 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioRegistrosIniciales.apellido_titular2" type="text" id="apellido-titular2" name="apellido" required> 
                    <div v-if="errorsRegistrosIniciales.apellido_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.apellido_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="documento-titular2">NÚMERO DE DOCUMENTO 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioRegistrosIniciales.documento_titular2" type="text" id="documento-titular2" name="documento" required>
                    <div v-if="errorsRegistrosIniciales.documento_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.documento_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="celular-titular2">TELÉFONO CELULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioRegistrosIniciales.celular_titular2" type="tel" id="celular-titular2" name="celular" required>
                    <div v-if="errorsRegistrosIniciales.celular_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.celular_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="email-titular2">EMAIL (REQUERIDO):</label>
                    <input v-model="formDataFormularioRegistrosIniciales.email_titular2" type="email" id="email-titular2" name="email" @input="validarEmail" required>
                    <div v-if="errorsRegistrosIniciales.email_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.email_titular2">{{ error }}</li>
                        </ul>
                    </div>
                    
                    <label class="archivo-titular2" for="archivotitular2">Adjuntar el documento del 2° Titular del Criadero (REQUERIDO):</label>
                    <input type="file" id="archivo" name="archivo3" @change="handleFileChange($event, 'archivo3')" multiple required>
                    <div v-if="errorsRegistrosIniciales.archivo3" class="error-message">
                        <ul>
                        <li v-for="error in errorsRegistrosIniciales.archivo3">{{ error }}</li>
                        </ul>
                    </div>
                    <br>
                </div>
                <label for="terminos-y-condiciones" id="terminos-y-condiciones" style="font-size: 15px; display: flex; align-items: center;" >
                  <input v-model="formDataFormularioRegistrosIniciales.terminos_condiciones"  type="checkbox" id="terminos-y-condiciones" style="margin-right: 5px;" >  
                  He leído y acepto los Términos de Uso y Política de Privacidad (REQUERIDO)
                </label>
                <div v-if="errorsRegistrosIniciales.terminos_condiciones" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errorsRegistrosIniciales.terminos_condiciones">{{ error }}</li>
                  </ul>
                </div>
                <label for="declaracion-jurada" id="declaracion-jurada"  style="font-size: 15px; display: flex;" >
                  <input  v-model="formDataFormularioRegistrosIniciales.declaracion_jurada" type="checkbox" id="declaracion-jurada" style="margin-right: 5px;" >  
                  Declaro que los datos proporcionados son reales y están bajo condición de Declaración Jurada (REQUERIDO)
                </label>
                <div v-if="errorsRegistrosIniciales.declaracion_jurada" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errorsRegistrosIniciales.declaracion_jurada">{{ error }}</li>
                  </ul>
                </div>
                
                <input class="submit" type="submit" value="ENVIAR">
                <span id="mensajeJS"></span>
        </form>

        
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
        archivos: {
        archivo1: [],
        archivo2: [],
        archivo3: [],
      },
      formDataFormularioRegistrosIniciales: {},
      errorsRegistrosIniciales: {},
      errorMessageRegistrosIniciales: null,
    };
  },

  methods: {

    handleFileChange(event, inputName) {
      const files = event.target.files;
      if (files.length > 0) {
        this.archivos[inputName] = files;
      }
    },

    formularioRegistroInicial (){
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitRegister' })
            .then(token => {
                const formDataFormularioRegistrosIniciales = new FormData(); 
                formDataFormularioRegistrosIniciales.append('token', token);

                for (let key in this.formDataFormularioRegistrosIniciales) {
                    formDataFormularioRegistrosIniciales.append(key, this.formDataFormularioRegistrosIniciales[key]);
                }

                // Agrega el archivo adjunto al FormData
                if (this.archivos.archivo1) {
                    for (let i = 0; i < this.archivos.archivo1.length; i++) {
                        formDataFormularioRegistrosIniciales.append('archivo1', this.archivos.archivo1[i]);
                    }
                }

                if (this.archivos.archivo2) {
                    for (let i = 0; i < this.archivos.archivo2.length; i++) {
                        formDataFormularioRegistrosIniciales.append('archivo2', this.archivos.archivo2[i]);
                    }
                }

                if (this.archivos.archivo3) {
                    for (let i = 0; i < this.archivos.archivo3.length; i++) {
                        formDataFormularioRegistrosIniciales.append('archivo3', this.archivos.archivo2[i]);
                    }
                }
                // Envío del formulario
                axios.post('http://localhost:3000/formulario-registros-iniciales', formDataFormularioRegistrosIniciales, {
                    headers: {'Content-Type': 'multipart/form-data',},
                })
                .then(response => {
                    console.log(response);
                    this.errorMessageRegistrosIniciales = null;
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Correo enviado.';
                    mensaje.className = 'enviado'; 
                }) 
                .catch(error => {
                    if (error.response) {
                        // La solicitud fue hecha y el servidor respondió con un código de estado diferente de 2xx
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    if (error.response.status === 422 ) {
                        console.log('Errores de validación:', error.response.data.errors);
                        this.errorsRegistrosIniciales = {}; // Reinicia los mensajes de error
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    // Asigna los mensajes de error a cada campo correspondiente
                    error.response.data.errors.forEach(err => {
                        // Verifica si ya hay un array de errores para el campo actual
                        if (!this.errorsRegistrosIniciales[err.path]) {
                            this.errorsRegistrosIniciales[err.path] = [];
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                            mensaje.className = 'error';
                        }
                        // Agrega el nuevo mensaje de error al array de errores
                        this.errorsRegistrosIniciales[err.path].push(err.msg);
                    });
                    } else {
                        // Otros errores del servidor
                        console.log('Error del servidor:', error.response.data);
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    }
                } else if (error.request) {
                    // La solicitud fue hecha pero no se recibió respuesta
                    console.log('No se recibió respuesta del servidor');
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                } else {
                    // Otros errores
                    console.log('Error en la solicitud:', error.message);
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                }
                })
            })
        })
    },

  },
};
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container form, .if_container {
  width: 60%; 
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.if_container{
  width: 100%;
}

label, a{
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input, select{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}

fieldset{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    height: 7vh;
    width: 15%;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
   h2{
    font-size: 1.3rem;
   }
   .submit{
    font-size: 15px;
  }
}
@media (max-width: 800px){
    .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 90%;
    padding-bottom: 0;
  }

  h2{
    font-size: 17px;
    text-wrap: balance;
  }

  h3 {
    font-size: 15px;
    text-wrap: balance;
  }

  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }

  select{
    height: 3vh;
    font-size: 13px;
  }
  
  #terminos-y-condiciones, #declaracion-jurada{
    font-size: 11px !important;
    align-items: end !important;
  }

  .submit{
    width: 25%;
    height: 5vh;
  }
}
</style>