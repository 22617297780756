<template>
    <section class="register_form_container">
      <div class="container">
        <h2 class="register_form_content title">Formulario para Registros Iniciales</h2>
        <p class="register_form_content subtitle">  Complete el formulario según los datos requeridos. <br><br> 
        Al finalizar deberá enviar escaneo o foto, del DNI parte frontal y fotos del perro. <br> Deberá poder observarse bien y en forma completa.</p>
        <FormRegister/>
      </div>
    </section>
  </template>
  
  
  <script setup>
  import FormRegister from '@/components/FormRegister.vue';
  import { onMounted, onUnmounted } from 'vue';
  import { canonicalService } from '../canonicalService';
  import { titleChange } from '../titleChange';

  onMounted(() => {
    canonicalService.addLink('https://kennelclubargentino.org.ar/formulario-registros-iniciales/');
  });
  onUnmounted(() => {
    canonicalService.removeLink('https://kennelclubargentino.org.ar/formulario-registros-iniciales/');
  });
  
  titleChange.setTitle('/formulario-registros-iniciales', 'Formulario para Registros Iniciales');

  </script>
  
  
  <style lang="scss" scoped>
  .register_form_container{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
  }
  
  .container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: zoomIn 2s ease;
  }
  
  @keyframes zoomIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }
  .title{
      text-align: center;
      color: #243757;
      font-size: 45px;
      padding-bottom: 0;
      margin-bottom: 15px;
    }
  
    .subtitle{
      text-align: center;
      color: grey;
      font-size: 22px;
      margin-bottom: 60px;
    }

  @media (max-width: 1280px){
    .register_form_container{
      padding-top: 90px; 
    }
    .container{
      padding: 10px 100px 30px 100px;
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
      text-wrap: balance;
    }
  }
  @media (max-width: 800px){
    .register_form_container{
      padding: 10px;
      padding-bottom: 20px;
    }

    .container{
    padding: 10px 35px;
    overflow: hidden;
   }

   .title{
      font-size: 25px;
      text-wrap: balance;
    }

    .subtitle{
      font-size: 18px;
      margin-bottom: 25px;
      text-wrap: balance;
    }
  }
  </style>