<template>
    <div class="form_container">
        <form @submit.prevent="submitForm" method="post" action="/formulario" novalidate enctype="multipart/form-data">
                <label for="nombre">NOMBRE (REQUERIDO):</label>
                <input v-model="formData.nombre" type="text" id="nombre" name="nombre">
                <div v-if="errors.nombre && errors.nombre.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.nombre">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO (REQUERIDO):</label>
                <input v-model="formData.apellido" type="text" id="apellido" name="apellido"> 
                <div v-if="errors.apellido && errors.apellido.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.apellido">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="organizacion">ORGANIZACION:</label>
                <input v-model="formData.organizacion" type="text" id="organizacion" name="organizacion">
                    <br>
                <label for="telefono">TELEFONO (REQUERIDO):</label>
                <input v-model="formData.telefono" type="tel" id="telefono" name="telefono">
                <div v-if="errors.telefono && errors.telefono.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.telefono">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email" type="email" id="email" name="email">
                <div v-if="errors.email && errors.email.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.email">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD / LOCALIDAD (REQUERIDO):</label>
                <input v-model="formData.ciudad" type="text" id="ciudad" name="ciudad">
                <div v-if="errors.ciudad && errors.ciudad.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.ciudad">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formData.provincia" type="text" id="provincia" name="provincia">
                <div v-if="errors.provincia && errors.provincia.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.provincia">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="pais">PAIS (REQUERIDO):</label>
                <input v-model="formData.pais" type="text" id="pais" name="pais">
                <div v-if="errors.pais && errors.pais.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.pais">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="asunto">ASUNTO (REQUERIDO):</label>
                <input v-model="formData.asunto" type="text" id="asunto" name="asunto"> 
                <div v-if="errors.asunto && errors.asunto.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.asunto">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label for="mensaje">TU MENSAJE (REQUERIDO):</label>
                <textarea class="mensaje" v-model="formData.mensaje" type="text" id="mensaje" name="mensaje"></textarea>
                <div v-if="errors.mensaje && errors.mensaje.length > 0" class="error-message">
                  <ul>
                    <li v-for="error in errors.mensaje">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                <label class="archivo" for="archivo">Adjunta un archivo (.pdf, .doc, .docx, .png, .jpg, .jpeg):</label>
                <input type="file" id="archivo" name="archivo" @change="handleFileChange" required>
                <div v-if="errors.archivo" class="error-message">
                  <ul>
                    <li v-for="error in errors.archivo">{{ error }}</li>
                  </ul>
                </div>
                <br>

                <input class="submit" type="submit" value="ENVIAR">
                <span id="mensajeJS"></span>
                
        </form>
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
      formData: {},
      archivo: null,
      errors: {},
      errorMessage: null,
    };
  },
  methods: {
    handleFileChange(event) {
      this.archivo = event.target.files[0];
    },

    submitForm (){
      grecaptcha.ready(() => {
        grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitContact' })
          .then(token => {
            const formData = new FormData(); 
            formData.append('token', token);
            // Agrega todos los campos de formData al nuevo FormData
              for (let key in this.formData) {
                formData.append(key, this.formData[key]);
              }
            // Agrega el archivo adjunto al FormData
              if (this.archivo) {
                formData.append('archivo', this.archivo);
              }
              // Envío del formulario
              axios.post('http://localhost:3000/formulario', formData,{
                headers: {
                  'Content-Type': 'multipart/form-data', // Establece el tipo de contenido como multipart/form-data
                },
              })
                .then(response => {
                  console.log(response);
                  this.errors = {};
                  let mensaje = document.getElementById('mensajeJS');
                  mensaje.textContent = 'Correo enviado.';
                  mensaje.className = 'enviado'; 
                }) 
                .catch(error => {
                  if (error.response) {
                  // La solicitud fue hecha y el servidor respondió con un código de estado diferente de 2xx
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                  if (error.response.status === 422) {
                    // Errores de validación
                    console.log('Errores de validación:', error.response.data.errors);
                    this.errors = {}; // Reinicia los mensajes de error
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                    // Asigna los mensajes de error a cada campo correspondiente
                    error.response.data.errors.forEach(err => {
                      // Verifica si ya hay un array de errores para el campo actual
                      if (!this.errors[err.path]) {
                        this.errors[err.path] = [];
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                      }

                      // Agrega el nuevo mensaje de error al array de errores
                      this.errors[err.path].push(err.msg);
                    });
                  } else {
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                    // Otros errores del servidor
                    console.error('Error del servidor:', error.response.data);
                  }
                } else if (error.request) {
                  // La solicitud fue hecha pero no se recibió respuesta
                  let mensaje = document.getElementById('mensajeJS');
                  mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                  mensaje.className = 'error';
                  console.log('No se recibió respuesta del servidor');
                } else {
                  // Otros errores
                  let mensaje = document.getElementById('mensajeJS');
                  mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                  mensaje.className = 'error';
                  console.error('Error en la solicitud:', error.message);
                }
              })
          })
      })
    }
  },
}    
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_container form {
  width: 60%; 
  display: flex;
  flex-direction: column;
}

label{
    font-size: 17px;
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}
.mensaje{
    height: 13vh;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    height: 5vh;
    width: 12%;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
  .submit{
    width: 17%;
    height: 6vh;
    margin-bottom: 0;
    margin-top: 40px;
  }
}

@media (max-width: 1200px){
  .submit{
    font-size: 1rem;
  }
}

@media (max-width: 950px){
  .submit{
    width: 25%;
  }

}

@media (max-width: 800px){
  .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 80%;
  }
}

@media (max-width: 500px){
  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }
  
  .submit{
    width: 25%;
    height: 5vh;
  }
}
</style>