<template>
    <section>
        <section class="pedigree">
            <div class="pedigree_container">
                <h2 class="pedigree_content title">Significado de Pedigree</h2>
                <div class="pedigree_content paragraph"> Podemos definir que pedigree es <i>la adaptación gráfica de la voz inglesa pedigree</i>, que significa <b>'genealogía de un animal de raza'</b> y que forma parte del diccionario de la Real Academia Española (RAE) como <b>pedigrí</b>.
                    <br><br>
                El origen remoto del concepto nos lleva a la expresión francesa <i>pied de grue (“pata de grulla”)</i>, que se utilizaba para nombrar a las tres marcas rectas que los primeros criadores de caballo utilizaban para marcarlos.
                    <br><br>
                El pedigree es la ascendencia biológica del animal doméstico; permite comprobar la pureza de raza. Esto quiere decir que un animal con pedigree posee ciertas características propias de su raza que le diferencian del resto.
                    <br><br>
                El certificado se obtiene recién al demostrar la pureza de raza del animal por varias generaciones. Por lo general, estos certificados son emitidos por asociaciones, federaciones o clubes.
                    <br><br>
                También <b>puede ser considerado un documento</b> que, según quien lo emita, podrá ser «privado» u «Oficial». <br>
                Para que sea Oficial, la federación, asocación o club, debe contar con un nombramiento de un Gobierno Nacional que lo habilite para tal fin. Cuando eso sucede, el Documento de pedigrí pasa a tener caracter de Documento Oficial Gubernamental.
                    <br><br>
                En el caso de un Documento Oficial, además de la pureza de raza, el mismo, <b>Certifica Propiedad Legal ante la Justicia</b>. Un Documento Oficial no debe ser desconocido por nadie.
                </div>
            </div>
        </section>
    </section>
</template>


<script setup>
import { onMounted, onUnmounted } from 'vue';
import { canonicalService } from '../canonicalService';
import { titleChange } from '../titleChange';

onMounted(() => {
  canonicalService.addLink('https://kennelclubargentino.org.ar/significado-de-pedigree/');
});
onUnmounted(() => {
  canonicalService.removeLink('https://kennelclubargentino.org.ar/significado-de-pedigree/');
});

titleChange.setTitle('/significado-pedigree', 'Significado de Pedigree')

</script>


<style lang="scss" scoped>
a{
    text-decoration: none;
}
.pedigree{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
}
.pedigree_container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: slideFromBottom 1s ease-in-out;
}

@keyframes slideFromBottom {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }

.title{
    text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
}
.paragraph{
    text-align: justify;
    color: grey;
    font-size: 22px;
    text-align: left;
    line-height: 1.5;
    word-spacing: 7px;
}

@media (max-width: 1280px){
    .pedigree{
        padding-top: 90px; 
    }
    .pedigree_container{
        padding: 10px 100px 30px 100px;
    }
    .title{
        font-size: 35px;
    }

    .paragraph{
        font-size: 20px;
        word-spacing: 5px;
        text-align: justify;
    }

}

@media (max-width: 800px){
    .pedigree{
        padding: 10px;
        padding-bottom: 20px;
    }
    .pedigree_container{
        padding: 10px 35px;
    }
    .title{
        font-size: 25px;
    }

    .paragraph{
        font-size: 14px;
    }
}
</style>