<template>
  <section class="macho_container">
    <div class="container">
      <h2 class="macho_content title">Denuncia de Servicio Dueño del Macho</h2>
      
      <p class="macho_content subtitle">Este formulario debe ser completado exclusivamente por el dueño del macho. <br> "El Kennel Club Digital NO se responsabiliza de la cesión de su documentación privada"</p>
      <div class="macho_content subt">
          <h4 ><i>Autorizo a la Secretaria del Kennel Club Digital a inscribir mi perro macho <br> en el servicio / monta con la perra según los datos consignados abajo.</i></h4>
      </div>
      <FormMacho/>
    </div>
  </section>
</template>
  
  
<script setup>
  import FormMacho from '../components/FormMacho.vue';
  import { onMounted, onUnmounted } from 'vue';
  import { canonicalService } from '../canonicalService';
  import { titleChange } from '../titleChange';

  onMounted(() => {
    canonicalService.addLink('https://kennelclubargentino.org.ar/denuncia-de-servicio-dueno-del-macho/');
  });
  onUnmounted(() => {
    canonicalService.removeLink('https://kennelclubargentino.org.ar/denuncia-de-servicio-dueno-del-macho/');
  });
  
  titleChange.setTitle('/denuncia-de-servicio-dueno-del-macho', 'Denuncia de Servicio Dueño del Macho');

</script>
  
  
<style lang="scss" scoped>
  .macho_container{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
  }
  
  .container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: zoomIn 2s ease;
  }
  
  @keyframes zoomIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }
  .title{
      text-align: center;
      color: #243757;
      font-size: 45px;
      padding-bottom: 0;
      margin-bottom: 15px;
    }
  
    .subtitle{
      text-align: center;
      color: grey;
      font-size: 22px;
      margin-bottom: 60px;
    }

    .subt{
      text-align: center;
      font-size: 1.4rem;
      margin: 40px;
    }

  @media (max-width: 1280px){
    .macho_container{
      padding-top: 90px; 
    }
    .container{
      padding: 10px 100px 30px 100px;
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
      text-wrap: balance;
    }

    .subt{
      font-size: 1.2rem;
    }
  }
  @media (max-width: 800px){
   .macho_container{
    padding: 10px;
    padding-bottom: 20px;
   }

   .container{
    padding: 10px 35px;
    overflow: hidden;
   }

   .title{
      font-size: 25px;
      text-wrap: balance;
    }

    .subtitle{
      font-size: 18px;
      margin-bottom: 25px;
      text-wrap: balance;
    }

    .subt{
      margin: 30px 0;
    }

    .subt h4{
      text-wrap: balance !important;
      font-size: 17px;
      margin: 0;
    }
  }
</style>