<template>
    <section class="dog_container">
      <div class="container">
        <h2 class="dog_content title">Denuncia de Venta de Canino</h2>
        
        <p class="dog_content subtitle"> Complete el formulario según los datos que figuran <br>en el documento de cesión que le entrego el criador. <br><br>
        EL TRÁMITE ES COMPLETAMENTE GRATUITO </p>
        <FormDogComplaint/>
      </div>
    </section>
  </template>
  
  
  <script setup>
  import FormDogComplaint from '@/components/FormDogComplaint.vue';
  import { onMounted, onUnmounted } from 'vue';
  import { canonicalService } from '../canonicalService';
  import { titleChange } from '../titleChange';

  onMounted(() => {
    canonicalService.addLink('https://kennelclubargentino.org.ar/denuncia-de-venta-de-canino/');
  });
  onUnmounted(() => {
    canonicalService.removeLink('https://kennelclubargentino.org.ar/denuncia-de-venta-de-canino/');
  });
  
  titleChange.setTitle('/denuncia-de-venta-de-canino', 'Denuncia de Venta de Canino');
  
  </script>
  
  
  <style lang="scss" scoped>
  .dog_container{
    background-color: #243757;
    background-image: url(../assets/dogprint.svg);
    background-repeat: repeat;
    padding: 90px;
    padding-bottom: 40px;
    display: grid;
    padding-top: 180px;
    place-items: center;
  }
  
  .container{
    background-color: hwb(0 100% 0%);
    border-radius: 8px;
    width: 80%;
    padding: 10px 150px;
    margin-top: 20px;
    padding-bottom: 50px;
    animation: zoomIn 2s ease;
  }
  
  @keyframes zoomIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }
  .title{
      text-align: center;
      color: #243757;
      font-size: 45px;
      padding-bottom: 0;
      margin-bottom: 15px;;
    }
  
    .subtitle{
      text-align: center;
      color: grey;
      font-size: 22px;
      margin-bottom: 60px;
    }

    .subt{
      text-align: center;
      font-size: 1.4rem;
      margin: 40px;
    }

  @media (max-width: 1280px){
    .dog_container{
      padding-top: 90px;
    }
    .container{
      padding: 10px 100px 30px 100px;
    }
    .title{
      font-size: 35px;
    }
    .subtitle{
      font-size: 20px;
      margin-bottom: 40px;
      text-wrap: balance;
    }
  }

  @media (max-width: 800px){
    .dog_container{
      padding: 10px;
      padding-bottom: 20px;
    }

    .container{
      padding: 10px 35px;
      padding-bottom: 20px;
      overflow: hidden;
   }

   .title{
      font-size: 25px;
      text-wrap: balance;
    }

    .subtitle{
      font-size: 18px;
      margin-bottom: 25px;
      text-wrap: balance;
    }
  }
  </style>