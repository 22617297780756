<template>
    <div class="form_container">
        <form @submit.prevent="formularioVentaCanino" action="http://localhost:3000/formulario-venta-canino" method="post" novalidate enctype="multipart/form-data">
            <a href="declaracion-jurada.pdf" download style="font-size: 15px; color: dodgerblue;" >CONSULTE NUESTRA POLÍTICA DE PRIVACIDAD</a> <br> <br>
              <a href="terminos-condiciones.pdf" download style="font-size: 15px; color: dodgerblue;">COSULTE AVISO LEGAL - TERMINOS DE USO</a>
              <br><br>
            <h3>SR/A: Presidente del Kennel Club Digital <br><br>
            Tengo el agrado de dirigirme a Ud., a los efectos de COMUNICARLE LA DENUNCIA DE VENTA DEL
            CANINO DE PURA RAZA del CRIADERO</h3>
            <label for="fecha">FECHA DE HOY (REQUERIDO):</label>
            <input v-model="formDataFormularioVentaCanino.fecha" type="date" id="fecha" name="fecha" required>
            <div v-if="errorsVentaCanino.fecha" class="error-message">
                <ul>
                    <li v-for="error in errorsVentaCanino.fecha">{{ error }}</li>
                </ul>
            </div>
            <br><br>
                <label for="nombre-ejemplar">NOMBRE DEL EJEMPLAR (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.nombre_ejemplar" type="text" id="nombre-ejemplar" name="nombre-ejemplar" required> 
                <div v-if="errorsVentaCanino.nombre_ejemplar" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.nombre_ejemplar">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="microchip">NÚMERO DE MICROCHIP:</label>
                <input v-model="formDataFormularioVentaCanino.microchip" type="text" id="microchip" name="microchip" >
                    <br>
                <label for="raza">RAZA (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.raza" type="text" id="raza" name="raza" required> 
                <div v-if="errorsVentaCanino.raza" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="variedad-raza">VARIEDAD DE RAZA - <i>si no hubiere una variedad, completar con "Sin Variedad" </i>- (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.variedad_raza" type="text" id="variedad-raza" name="variedad-raza" required> 
                <div v-if="errorsVentaCanino.variedad_raza" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.variedad_raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="color">COLOR (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.color" type="text" id="color" name="color" required>
                <div v-if="errorsVentaCanino.color" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.color">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="opciones">SEXO (REQUERIDO)</label>
                <select v-model="formDataFormularioVentaCanino.sexo" id="sexo" name="opciones" required>
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Macho">Macho</option>
                    <option value="Hembra">Hembra</option>
                </select>
                <div v-if="errorsVentaCanino.sexo" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.sexo">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="criador">CRIADOR (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.criador" type="text" id="criador" name="criador" required>
                <div v-if="errorsVentaCanino.criador" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.criador">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email-criador">EMAIL (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.email_criador" type="email" id="email-criador" name="email-criador" @input="validarEmail" required>
                <div v-if="errorsVentaCanino.email_criador" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.email_criador">{{ error }}</li>
                    </ul>
                </div>
                <br>

                <label class="archivo" for="archivo">Adjuntar FRENTE del Documento de Identidad del Criador (REQUERIDO):</label>
                <input type="file" id="frente-documento-criador" name="archivo" multiple @change="handleFileChange" required>
                <div v-if="errorsVentaCanino.archivo" class="error-message">
                  <ul>
                    <li v-for="error in errorsVentaCanino.archivo">{{ error }}</li>
                  </ul>
                </div>
                <br>

            <h2>VENDIDO AL SR/A</h2>
                <label for="nombre">NOMBRE DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.nombre" type="text" id="nombre" name="nombre" required>
                <div v-if="errorsVentaCanino.nombre" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.nombre">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.apellido" type="text" id="apellido" name="apellido" required> 
                <div v-if="errorsVentaCanino.apellido" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.apellido">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                    <label for="documento">NÚMERO DE DOCUMENTO (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.documento" type="text" id="documento" name="documento" required>
                <div v-if="errorsVentaCanino.documento" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.documento">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="celular">TELÉFONO CELULAR (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.celular" type="tel" id="celular" name="celular" required>
                <div v-if="errorsVentaCanino.celular" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.celular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.email_venta" type="email" id="email" name="email" @input="validarEmail" required>
                <div v-if="errorsVentaCanino.email_venta" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.email_venta">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="domicilio">DOMICILIO (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.domicilio" type="text" id="domicilio" name="domicilio" required> 
                <div v-if="errorsVentaCanino.domicilio" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.domicilio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="codigo-postal">CODIGO POSTAL (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.codigo_postal" type="text" id="codigo-postal" name="codigo-postal" required> 
                <div v-if="errorsVentaCanino.codigo_postal" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.codigo_postal">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.ciudad" type="text" id="ciudad" name="ciudad" required>
                <div v-if="errorsVentaCanino.ciudad" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.ciudad">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.provincia" type="text" id="provincia" name="provincia" required>
                <div v-if="errorsVentaCanino.provincia" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.provincia">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pais">PAÍS (REQUERIDO):</label>
                <input v-model="formDataFormularioVentaCanino.pais" type="text" id="pais" name="pais" required>
                <div v-if="errorsVentaCanino.pais" class="error-message">
                    <ul>
                        <li v-for="error in errorsVentaCanino.pais">{{ error }}</li>
                    </ul>
                </div>
                <label for="terminos-y-condiciones" id="terminos-y-condiciones" style="font-size: 15px; display: flex; align-items: center;" >
                  <input v-model="formDataFormularioVentaCanino.terminos_condiciones"  type="checkbox" id="terminos-y-condiciones" style="margin-right: 5px;" required>  
                  He leído y acepto los Términos de Uso y Política de Privacidad (REQUERIDO)
                </label>
                <div v-if="errorsVentaCanino.terminos_condiciones" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errorsVentaCanino.terminos_condiciones">{{ error }}</li>
                  </ul>
                </div>
                <label for="declaracion-jurada" id="declaracion-jurada" style="font-size: 15px; display: flex;" >
                  <input  v-model="formDataFormularioVentaCanino.declaracion_jurada" type="checkbox" id="declaracion-jurada" style="margin-right: 5px;" required>  
                  Declaro que los datos proporcionados son reales y están bajo condición de Declaración Jurada (REQUERIDO)
                </label>
                <div v-if="errorsVentaCanino.declaracion_jurada" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errorsVentaCanino.declaracion_jurada">{{ error }}</li>
                  </ul>
                </div>
                
                <input class="submit" type="submit" value="ENVIAR">
                <span id="mensajeJS"></span>
        </form>

        
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{ 
      archivo: null,
      formDataFormularioVentaCanino: {},
      errorsVentaCanino: {},
      errorMessageVentaCanino: null,
    };
  },

  methods: {

    handleFileChange(event) {
      this.archivo = event.target.files;
    },

    formularioVentaCanino (){
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitVentaCanino' })
                .then(token => {
                    const formDataFormularioVentaCanino = new FormData(); 
                    formDataFormularioVentaCanino.append('token', token);

                    for (let key in this.formDataFormularioVentaCanino) {
                        formDataFormularioVentaCanino.append(key, this.formDataFormularioVentaCanino[key]);
                    }

                    if (this.archivo) {
                        for (let i = 0; i < this.archivo.length; i++) {
                            formDataFormularioVentaCanino.append('archivo', this.archivo[i]);
                        }
                    }
                    console.log(formDataFormularioVentaCanino)
                // Envío del formulario
                    axios.post('http://localhost:3000/formulario-venta-canino', formDataFormularioVentaCanino, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        console.log(response);
                        this.errorsVentaCanino = {};
                        this.errorMessageVentaCanino = null;
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Correo enviado.';
                        mensaje.className = 'enviado'; 
                    }) 
                    .catch(error => {
                        if (error.response) {
                        // La solicitud fue hecha y el servidor respondió con un código de estado diferente de 2xx
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                            mensaje.className = 'error';
                        if (error.response.status === 422 ) {
                            console.log('Errores de validación:', error.response.data.errors);
                            this.errorsVentaCanino = {}; // Reinicia los mensajes de error
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                            mensaje.className = 'error';
                        // Asigna los mensajes de error a cada campo correspondiente
                        error.response.data.errors.forEach(err => {
                            // Verifica si ya hay un array de errores para el campo actual
                            if (!this.errorsVentaCanino[err.path]) {
                                this.errorsVentaCanino[err.path] = [];
                                let mensaje = document.getElementById('mensajeJS');
                                mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                                mensaje.className = 'error';
                            }
                            // Agrega el nuevo mensaje de error al array de errores
                            this.errorsVentaCanino[err.path].push(err.msg);
                        });
                        } else {
                        // Otros errores del servidor
                            console.log('Error del servidor:', error.response.data);
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                            mensaje.className = 'error';
                        }
                    } else if (error.request) {
                        // La solicitud fue hecha pero no se recibió respuesta
                        console.log('No se recibió respuesta del servidor');
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    } else {
                        // Otros errores
                        console.log('Error en la solicitud:', error.message);
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    }
                    })
                })
        })
    },

  },
};
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container form {
  width: 60%; 
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

label, a{
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input, select{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}

fieldset{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    height: 7vh;
    width: 15%;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
   h2{
    font-size: 1.3rem;
   }
   .submit{
    font-size: 15px;
  }
}
@media (max-width: 800px){
    .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 90%;
    padding-bottom: 0;
  }

  h2{
    font-size: 17px;
    text-wrap: balance;
  }

  h3 {
    font-size: 15px;
    text-wrap: balance;
  }

  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }

  select{
    height: 3vh;
    font-size: 13px;
  }
  
  #terminos-y-condiciones, #declaracion-jurada{
    font-size: 11px !important;
    align-items: end !important;
  }

  .submit{
    width: 25%;
    height: 5vh;
  }
}
</style>