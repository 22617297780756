<template>
  <section class="transfer_container">
    <div class="container">
      <h2 class="transfer_content title">Transferencias Online</h2>
      <h3 class="transfer_content title2">(Cambio de Titularidad)</h3>
      <p class="transfer_content subtitle"> Complete el formulario según los datos que figuran en el documento de cesión que le entrego el criador. <br><br> Al finalizar deberá enviar escaneado foto del DNI parte frontal y del Documento de Cesión que le entregó el criador, completo con sus datos y firmas de ambas partes.</p>
      <FormTransfer/>
    </div>
  </section>
</template>
  
  
<script setup>
import FormTransfer from '../components/FormTransfer.vue';
import { onMounted, onUnmounted } from 'vue';
import { canonicalService } from '../canonicalService';
import { titleChange } from '../titleChange';

onMounted(() => {
  canonicalService.addLink('https://kennelclubargentino.org.ar/transferencia-online/');
});
onUnmounted(() => {
  canonicalService.removeLink('https://kennelclubargentino.org.ar/transferencia-online/');
});


titleChange.setTitle('/transferencias-online', 'Transferencias Online (Cambio de Titularidad)');

</script>
  
  
<style lang="scss" scoped>
.transfer_container{
  background-color: #243757;
  background-image: url(../assets/dogprint.svg);
  background-repeat: repeat;
  padding: 90px;
  padding-bottom: 40px;
  display: grid;
  padding-top: 180px;
  place-items: center;
}

.container{
  background-color: hwb(0 100% 0%);
  border-radius: 8px;
  width: 80%;
  padding: 10px 150px;
  margin-top: 20px;
  padding-bottom: 50px;
  animation: zoomIn 2s ease;
}

@keyframes zoomIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.title{
  text-align: center;
    color: #243757;
    font-size: 45px;
    padding-bottom: 0;
    margin-bottom: 15px;
  }

  .title2{
    text-align: center;
    color: #243757;
    font-size: 2rem;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .subtitle{
    text-align: center;
    color: grey;
    font-size: 22px;
    margin-bottom: 60px;
  }

@media (max-width: 1280px){
  .transfer_container{
    padding-top: 90px; 
  }
  .container{
    padding: 10px 100px 30px 100px;
  }
  .title{
    font-size: 35px;
  }
  .title2{
    font-size: 30px;
  }
  .subtitle{
    font-size: 20px;
    margin-bottom: 40px;
    text-wrap: balance;
  }
}
@media (max-width: 800px){
  .transfer_container{
    padding: 10px;
    padding-bottom: 20px;
  }

  .container{
    padding: 10px 35px;
    overflow: hidden;
   }

   .title{
      font-size: 25px;
      text-wrap: balance;
    }

    .title2{
      font-size: 20px;
      text-wrap: balance;
      margin-bottom: 25px;
    }

    .subtitle{
      font-size: 16px;
      margin-bottom: 25px;
      text-wrap: balance;
    }
}
</style>