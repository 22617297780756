<template>
    <header>
      <section class="hero_container">
        <div class="hero">
            <div class="hero_content">
              <h1 class="hero_css">Kennel Club Digital</h1>
              <p class="hero_p">Registro Internacional Canino</p>
            </div>
        </div>
      </section> 
    </header>  

    <main>
      <section class="services_container" :class="{'mostrar': visible }">
        <h2 class="title">Nuestros Servicios</h2>
        <h3 class="subtitle">Garantía de calidad y reconocimiento global</h3>
        <ItemsServicios/>  
      </section>
      
      <section class="about_container">
          <h2 class="title">Sobre Nosotros</h2>
          <h3 class="subtitle">Reconocemos todas las razas y variedad de colores</h3>
          <HomeCards/>
      </section>

      <section class="kcursos_container">
        <p class="kc_text">El Kennel Club Cursos <i style="color: rgb(216, 211, 211);">-plataforma de cursos online auspiciada por el Kennel Club Argentino-</i> tiene como objetivo difundir e informar sobre los conocimientos en tema de crianza, tenencia responsable, bienestar animal, entre otras actividades que incluyen la convivencia con los caninos. </p>
        <a href="https://kennelclubcursos.com/" target="_blank"><button class="kc_button"><p class="button_text">ENTRA A NUESTRA PLATAFORMA</p></button></a>
      </section>

      <section class="questions_container">
        <h2 class="title">Preguntas Frecuentes</h2>
        <h3 class="subtitle">Estamos para aclarar tus dudas y consultas</h3>
        <Questions/>
      </section>

      <section class="form_container">
        <h2 class="title">Contáctate con Nosotros</h2>
        <p class="subtitle">Completa nuestro formulario de contacto <br> y déjanos saber cómo podemos ayudarte.</p>
        <FormContact/>
      </section>
    </main>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import ItemsServicios from '../components/ItemsServicios.vue';
  import HomeCards from '../components/HomeCards.vue';
  import Questions from '../components/Questions.vue';
  import FormContact from '../components/FormContact.vue';
  import { titleChange } from '../titleChange';

  // Configurando services_container transition 
  const visible = ref(false);

  onMounted(() => {
    setTimeout(() => {
      visible.value = true;
    }, 500); 
  });

  titleChange.setTitle();

</script>

<style scoped lang="scss">

  header{
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: -1;
    background-image: linear-gradient(180deg, #0000003c 0%,#0000004c 100%), url(../assets/dog.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
// Hero 
  .hero_container {
    display: grid;
    width: 100%;
    height: 80vh;
    padding-top: 60px;
    place-items: center;
  }

  .hero{
    padding: 30px;
  }
  .hero_content{
    text-align: center;
    display: inline-block;
  }
  .hero_css{
    font-size: 7rem;
    font-family: 'Elen Sans';
    color: #243757;
    margin: 100px 100px 40px 100px;
    text-shadow: 1px 1px 4px #fff;
    -webkit-text-stroke: 2px #fff;
  }
  .hero_p{
    text-align: center;
    font-family: 'Elen Sans';
    margin: 0;
    color: #fff;
    font-size: 4rem;
    -webkit-text-stroke: 2px #243757;
    white-space: nowrap;
    overflow: hidden;
  }

// Services
  .services_container{
   padding: 90px;
   padding-top: 50px;
   padding-bottom: 0;
   overflow: hidden;
   opacity: 0;
   transition: opacity 2s ease-in-out;
  }
  .mostrar{
    opacity: 1;
  }

  .services_content{
    margin: 20px;
    padding-bottom: 40px;
  }
  .title{
    text-align: center;
    color: #243757;
    font-size: 3.5rem;
    padding-bottom: 0;
  }

  .subtitle{
    text-align: center;
    color: grey;
    font-size: 1.5rem;
  }

// About
.about_container{
  padding: 90px;
  padding-top: 50px;
  padding-bottom: 40px;
}

.about{
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about_content{
  margin-top: 20px;
  margin-bottom: 20px;
}
// KCursos
.kcursos_container{
  background-color: #15527F;
  height: 150px;
  margin: 10px 0 60px 0;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-content: center;
  align-items: center;
}
.kc_text{
  text-align: left;
  margin: 10px 0 10px 110px;
  color: #fff;
  font-size: 21px;
}
.kc_button{
  background-color: #fff;
  font-weight: bold;
  height: 60px;
  width: 450px;
  border: none;
  border-radius: 3px;
  margin: 0 40px 0 160px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}
.kc_button:hover{
  background-color: #e19f41;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.9)
}
.button_text{
  text-align: center;
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0;
}
// Questions
.questions_content {
  margin: 20px;
}

// Form
.form_container{
  padding: 90px;
  padding-top: 30px;
  padding-bottom: 0;
}

.form_container p{
  margin-bottom: 0;
}


@media (max-width: 1366px) {
  // Hero
  .hero_css{
    font-size: 70px;
    margin-top: 50px;
  }
  .hero_p{
    font-size: 50px;
    overflow: visible
  }
 
  .title{
    font-size: 40px;
    padding-bottom: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .subtitle{
    font-size: 25px;
    margin-top: 0 !important;
    margin-bottom: 30px !important;
  }

  // Services  
  .services_container{
    padding: 50px 60px;
  }

  // About
  .about_container{
    padding: 0 60px 50px 60px
  }

  // KCursos
  .kc_text{
    margin: 10px 60px 10px 110px;
    font-size: 19px;
    text-align: justify;
  }
  .kc_button{
    margin: 0;
    width: 300px;
    position: relative;
    left: 10%;
  }
  .button_text{
    font-size: 18px;
  }

  // Questions
  .questions_container{
    padding: 0 60px 50px 60px
  }

  div.questions_container{
    padding-bottom: 0;
  }

  // Contact form
  section.form_container{
    padding: 0 60px 50px 60px;
  }

  .form_container p{
    text-wrap: balance;
    font-size: 20px;
  }

  div.form_container{
    padding: 0;
  }
}

@media (max-width: 1115px){
    .kc_text{
      font-size: 1.1rem;
    }
}

@media (max-width: 1070px){
  .about_container{
    padding: 50px 90px 40px 90px;
  }

  .kc_button{
    width: 350px;
  }

  .button_text{
    font-size: 1.1rem;
  }
}

@media (max-width: 1000px){
  .hero_css{
    margin-top: 0;
  }
}

@media (max-width: 950px){
  .about_container{
    padding: 50px 60px;
  }

  .kc_text{
    margin: 10px 20px;
  }

  .kc_button{
    margin-right: 30px;
  }
}

@media (max-width: 800px){
  .title{
    font-size: 35px;
  }
  // Hero
  header{
    height: 70vh;
  }
  .hero_container{
    height: 60vh;
  }
  .hero_css{
    margin: 0;
    font-size: 4rem;
    text-shadow: 1px 1px #fff;
  }
  .hero_p{
    white-space: unset;
    font-size: 3rem;
    margin-top: 10px;
  }
  // Services
  .services_container{
    padding-top: 30px;
  }
  .services_content{
    padding-bottom: 0;
  }
  // KCursos
  .kcursos_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    height: auto;
    justify-items: center; 
    align-items: center;
  }
  .kc_text{
    margin: 20px 25px 15px 25px;
    text-align: center;
  }
  .kc_button{
    width: 230px;
    height: 55px;
    margin: 10px 0 25px 0;
  }
  .button_text{
    font-size: 15px;
  }
}

@media (max-width: 500px){
  .hero_container{
    height: 70vh;
    padding-top: 0;
  }
  .hero_css{
    font-size: 3rem;
    -webkit-text-stroke: 1px #fff;
    text-wrap: balance;
  }

  .hero_p{
    font-size: 2.6rem;
    -webkit-text-stroke: 1px #243757;
    text-wrap: balance;
  }

  .title{
    font-size: 30px;
    padding: 0 20px;
  }
  .subtitle{
    font-size: 20px;
    text-wrap: balance;
    margin-bottom: 10px !important;
    padding: 0 30px;
  }

  // Services

  .services_container{
    padding: 40px 0;
  }

  // About
  .about_container{
    padding: 0;
    padding-bottom: 40px;
  }

  // KCursos
  .kcursos_container{
    margin: 30px 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .kc_text{
    margin: 10px auto 0 auto;
    text-wrap: balance;
  }

  .kc_button{
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    margin-bottom: 10px;
  }

  // Questions
  section.questions_container{
    padding: 20px 0 40px 0;
  }

  div.questions_container{
    padding: 30px 20px;
  }

  // Form
  section.form_container{
    padding: 0 0 40px 0;
  }

  section.form_container .subtitle{
    font-size: 17px;
  }

  div.form_container{
    padding: 10px 0;
  }

  
}
</style>
