<template>
    <div class="form_container">
        <form @submit.prevent="formularioTransfer" action="http://localhost:3000/formulario-transferencias" method="post" novalidate enctype="multipart/form-data">
            <a href="declaracion-jurada.pdf" download style="font-size: 15px; color: dodgerblue;" >CONSULTE NUESTRA POLÍTICA DE PRIVACIDAD</a> <br> <br>
              <a href="terminos-condiciones.pdf" download style="font-size: 15px; color: dodgerblue;">COSULTE AVISO LEGAL - TERMINOS DE USO</a>
              <br><br>
            <label for="fecha">FECHA DE HOY (REQUERIDO):</label>
            <input v-model="formDataFormularioTransfer.fecha" type="date" id="fecha" name="fecha" required>
            <div v-if="errorsTransfer.fecha" class="error-message">
                <ul>
                    <li v-for="error in errorsTransfer.fecha">{{ error }}</li>
                </ul>
            </div>
            <br><br>
            <h2>DATOS DEL PERRO</h2>
                <label for="nombre-perro">NOMBRE COMPLETO DEL PERRO, <i>como figura en el papel</i> - (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.nombre_perro" type="text" id="nombre-perro" name="nombre-perro" required>
                <div v-if="errorsTransfer.nombre_perro" class="error-message">
                <ul>
                    <li v-for="error in errorsTransfer.nombre_perro">{{ error }}</li>
                </ul>
            </div>
                    <br>
                <label for="nuevo-nombre-perro">NUEVO NOMBRE (*Solo se puede cambiar la parte del nombre que NO incluye el nombre criadero, costo adicional que puede consultar en la lista de precios, si no desea cambiarlo deje el campo en blanco):</label>
                <input v-model="formDataFormularioTransfer.nuevo_nombre_perro" type="text" id="nuevo-nombre-perro" name="nuevo-nombre-perro">
                    <br>
                <label for="registro-perro">NÚMERO DE REGISTRO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.registro_perro" type="text" id="registro-perro" name="registro-perro" required>
                <div v-if="errorsTransfer.registro_perro" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.registro_perro">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="fecha">FECHA DE NACIMIENTO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.fecha_nacimiento" type="date" id="fecha-nacimiento" name="fecha" required>
                <div v-if="errorsTransfer.fecha_nacimiento" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.fecha_nacimiento">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="fecha">FECHA DE INSCRIPCION EN KCD (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.fecha_inscripcion" type="date" id="fecha-inscripcion-kcd" name="fecha" required>
                <div v-if="errorsTransfer.fecha_inscripcion" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.fecha_inscripcion">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="microchip-perro">NÚMERO DE MICROCHIP:</label>
                <input v-model="formDataFormularioTransfer.microchip" type="text" id="microchip-perro" name="microchip-perro">
                    <br>
                <label for="raza">RAZA (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.raza" type="text" id="raza" name="raza"> 
                <div v-if="errorsTransfer.raza" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="variedad-raza">VARIEDAD DE RAZA - <i>si no hubiere una variedad, completar con "Sin Variedad" </i>- (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.variedad_raza" type="text" id="variedad-raza" name="variedad-raza" required> 
                <div v-if="errorsTransfer.variedad_raza" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.variedad_raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="color">COLOR (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.color" type="text" id="color" name="color" required>
                <div v-if="errorsTransfer.color" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.color">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="patron">PATRÓN (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.patron" type="text" id="patron" name="patron" required>
                <div v-if="errorsTransfer.patron" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.patron">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pelo">TIPO DE PELO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.pelo" type="text" id="pelo" name="pelo" required>
                <div v-if="errorsTransfer.pelo" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.pelo">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="opciones">COLOR DE OJOS (REQUERIDO)</label>
                <select v-model="formDataFormularioTransfer.color_de_ojos" id="color-de-ojos" name="opciones" required>
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Verde">Verde</option>
                    <option value="Gris">Gris</option>
                    <option value="Ámbar">Ámbar</option>
                    <option value="Marrón">Marrón</option>
                    <option value="Celestes">Celestes</option>
                    <option value="Sarco">Sarco (un ojo de cada color)</option>
                </select>
                <div v-if="errorsTransfer.color_de_ojos" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.color_de_ojos">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="opciones">SEXO (REQUERIDO)</label>
                <select  v-model="formDataFormularioTransfer.sexo" id="sexo" name="opciones" required>
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Macho">Macho</option>
                    <option value="Hembra">Hembra</option>
                </select>
                <div v-if="errorsTransfer.sexo" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.sexo">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="nombre-criador">CRIADOR (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.nombre_criador" type="text" id="nombre-criador" name="nombre-criador" required>
                <div v-if="errorsTransfer.nombre_criador" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.nombre_criador">{{ error }}</li>
                    </ul>
                </div>
                    <br>
            <h2>DATOS DEL NUEVO PROPIETARIO</h2>
                <label for="nombre">NOMBRE DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.nombre" type="text" id="nombre" name="nombre" required>
                <div v-if="errorsTransfer.nombre" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.nombre">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.apellido" type="text" id="apellido" name="apellido" required> 
                <div v-if="errorsTransfer.apellido" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.apellido">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="documento">NÚMERO DE DOCUMENTO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.documento" type="text" id="documento" name="documento" required>
                <div v-if="errorsTransfer.documento" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.documento">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="telefono">TELÉFONO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.celular" type="tel" id="telefono" name="telefono" required>
                <div v-if="errorsTransfer.celular" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.celular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.email" type="text" id="email" name="email" @input="validarEmail" required>
                <div v-if="errorsTransfer.email" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.email">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="domicilio">DOMICILIO (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.domicilio" type="text" id="domicilio" name="domicilio" required>
                <div v-if="errorsTransfer.domicilio" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.domicilio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="codigo-postal">CÓDIGO POSTAL (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.codigo_postal" type="text" id="codigo-postal" name="codigo-postal" required>
                <div v-if="errorsTransfer.codigo_postal" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.codigo_postal">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD / LOCALIDAD (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.ciudad" type="text" id="ciudad" name="ciudad" required>
                <div v-if="errorsTransfer.ciudad" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.ciudad">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.provincia" type="text" id="provincia" name="provincia" required>
                <div v-if="errorsTransfer.provincia" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.provincia">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pais">PAIS (REQUERIDO):</label>
                <input v-model="formDataFormularioTransfer.pais" type="text" id="pais" name="pais" required>
                <div v-if="errorsTransfer.pais" class="error-message">
                    <ul>
                        <li v-for="error in errorsTransfer.pais">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label class="archivo" for="archivo">Adjuntar FRENTE de Documento de Identidad (REQUERIDO):</label>
                <input type="file" id="foto-documento" name="archivo1" @change="handleFileChange($event, 'archivo1')" required>
                <div v-if="errorsTransfer.archivo1" class="error-message">
                    <ul>
                    <li v-for="error in errorsTransfer.archivo1">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label class="archivo" for="archivo">Adjuntar Documento de Cesión de Propiedad (REQUERIDO):</label>
                <input type="file" id="cesion-propiedad" name="archivo2" @change="handleFileChange($event, 'archivo2')" required>
                <div v-if="errorsTransfer.archivo2" class="error-message">
                    <ul>
                    <li v-for="error in errorsTransfer.archivo2">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label class="archivo" for="archivo">Adjuntar Certificado de Microchip expedido por profesional (Si no lo tuviere, no adjuntar nada):</label>
                <input type="file" id="certificado-microchip" name="archivo3" @change="handleFileChange($event, 'archivo3')">
                <div v-if="errorsTransfer.archivo3" class="error-message">
                    <ul>
                    <li v-for="error in errorsTransfer.archivo3">{{ error }}</li>
                    </ul>
                </div>
                <br><br>
                
            <h2>DATOS DEL 2° TITULAR</h2> 
                <label for="mostrarForm">¿HAY 2° TITULAR?</label>
                <select v-model="formDataFormularioTransfer.mostrarForm" id="mostrarForm" name="mostrarForm" required>
                    <option value=Si>Sí</option>
                    <option value=No>No</option>
                </select>
                
                <div v-if="formDataFormularioTransfer.mostrarForm === 'Si'" class="if_container">
                    <label for="opciones">TIPO DE FIRMA (REQUERIDO)</label>
                    <select v-model="formDataFormularioTransfer.section" id="opciones" name="opciones" required>
                        <option value="" disabled selected>--Por favor, elige una opción--</option>
                        <option value="Indistinta">Indistinta</option>
                        <option value="Conjunta">Conjunta</option>
                    </select>
                    <div v-if="errorsTransfer.section" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.section">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="nombre-titular2">NOMBRE DEL 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioTransfer.nombre_titular2" type="text" id="nombre-titular2" name="nombre" required> 
                    <div v-if="errorsTransfer.nombre_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.nombre_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="apellido-titular2">APELLIDO DEL 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioTransfer.apellido_titular2" type="text" id="apellido-titular2" name="apellido" required> 
                    <div v-if="errorsTransfer.apellido_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.apellido_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="documento-titular2">NÚMERO DE DOCUMENTO 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioTransfer.documento_titular2" type="text" id="documento-titular2" name="documento" required>
                    <div v-if="errorsTransfer.documento_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.documento_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="celular-titular2">TELÉFONO CELULAR (REQUERIDO):</label>
                    <input v-model="formDataFormularioTransfer.celular_titular2" type="tel" id="celular-titular2" name="celular" required>
                    <div v-if="errorsTransfer.celular_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.celular_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="email-titular2">EMAIL (REQUERIDO):</label>
                    <input v-model="formDataFormularioTransfer.email_titular2" type="email" id="email-titular2" name="email" @input="validarEmail" required>
                    <div v-if="errorsTransfer.email_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.email_titular2">{{ error }}</li>
                        </ul>
                    </div>
                    
                    <label class="archivo-titular2" for="archivotitular2">Adjuntar el documento del 2° Titular del Criadero:</label>
                    <input type="file" id="archivo" name="archivo4" @change="handleFileChange($event, 'archivo4')" required>
                    <div v-if="errorsTransfer.archivo4" class="error-message">
                        <ul>
                        <li v-for="error in errorsTransfer.archivo4">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <label for="terminos-y-condiciones" id="terminos-y-condiciones" style="font-size: 15px; display: flex; align-items: center;" >
                  <input v-model="formDataFormularioTransfer.terminos_condiciones"  type="checkbox" id="terminos-y-condiciones" style="margin-right: 5px;" >  
                  He leído y acepto los Términos de Uso y Política de Privacidad (REQUERIDO)
                </label>
                <div v-if="errorsTransfer.terminos_condiciones" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errorsTransfer.terminos_condiciones">{{ error }}</li>
                  </ul>
                </div>
                <label for="declaracion-jurada" id="declaracion-jurada" style="font-size: 15px; display: flex;" >
                  <input  v-model="formDataFormularioTransfer.declaracion_jurada" type="checkbox" id="declaracion-jurada" style="margin-right: 5px;" >  
                  Declaro que los datos proporcionados son reales y están bajo condición de Declaración Jurada (REQUERIDO)
                </label>
                <div v-if="errorsTransfer.declaracion_jurada" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errorsTransfer.declaracion_jurada">{{ error }}</li>
                  </ul>
                </div>
                
            <input class="submit" type="submit" value="ENVIAR">
            <span id="mensajeJS"></span>
        </form>

    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
      archivos: {
        archivo1: null,
        archivo2: null,
        archivo3: null,
        archivo4: null,
      },
      formDataFormularioTransfer: {},
      errorsTransfer: {},
      errorMessageTransfer: null,
    };
  },

  methods: {

    handleFileChange(event, inputName) {
      const files = event.target.files;
      if (files.length > 0) {
        this.archivos[inputName] = files;
      }
    },

    formularioTransfer (){
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'transferSubmit' })
            .then(token => {
             const formDataFormularioTransfer = new FormData(); 
             formDataFormularioTransfer.append('token', token);

             for (let key in this.formDataFormularioTransfer) {
                formDataFormularioTransfer.append(key, this.formDataFormularioTransfer[key]);
             }

             // Agrega el archivo adjunto al FormData
             if (this.archivos.archivo1) {
                formDataFormularioTransfer.append('archivo1', this.archivos.archivo1[0]);
             }

             if (this.archivos.archivo2) {
                formDataFormularioTransfer.append('archivo2', this.archivos.archivo2[0]);
             }

             if (this.archivos.archivo3) {
                formDataFormularioTransfer.append('archivo3', this.archivos.archivo3[0]);
             }

             if (this.archivos.archivo4) {
                formDataFormularioTransfer.append('archivo4', this.archivos.archivo4[0]);
             }
            // Envío del formulario
                axios.post('http://localhost:3000/formulario-transferencias', formDataFormularioTransfer, {
                    headers: {'Content-Type': 'multipart/form-data',},
                })
                .then(response => {
                    console.log(response);
                    this.errorMessageTransfer = null;
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Correo enviado.';
                    mensaje.className = 'enviado'; 
                }) 
                .catch(error => {
                    if (error.response) {
                    // La solicitud fue hecha y el servidor respondió con un código de estado diferente de 2xx
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    if (error.response.status === 422 ) {
                        console.log('Errores de validación:', error.response.data.errors);
                        this.errorsTransfer = {}; // Reinicia los mensajes de error
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    // Asigna los mensajes de error a cada campo correspondiente
                    error.response.data.errors.forEach(err => {
                        // Verifica si ya hay un array de errores para el campo actual
                        if (!this.errorsTransfer[err.path]) {
                            this.errorsTransfer[err.path] = [];
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                            mensaje.className = 'error';
                        }
                        // Agrega el nuevo mensaje de error al array de errores
                        this.errorsTransfer[err.path].push(err.msg);
                    });
                    } else {
                    // Otros errores del servidor
                        console.log('Error del servidor:', error.response.data);
                        let mensaje = document.getElementById('mensajeJS');
                        mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                        mensaje.className = 'error';
                    }
                } else if (error.request) {
                    // La solicitud fue hecha pero no se recibió respuesta
                    console.log('No se recibió respuesta del servidor');
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                } else {
                    // Otros errores
                    console.log('Error en la solicitud:', error.message);
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Ocurrió un error al enviar el formulario.';
                    mensaje.className = 'error';
                }
                })
            })
        })
    }

  },
};
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container form, .if_container{
  width: 60%; 
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.if_container{
  width: 100%;
}

label, a{
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input, select{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}

fieldset{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    height: 7vh;
    width: 15%;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
   h2{
    font-size: 1.3rem;
   }
   .submit{
    font-size: 15px;
  }
}
@media (max-width: 800px){
    .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 90%;
    padding-bottom: 0;
  }

  h2{
    font-size: 17px;
    text-wrap: balance;
  }

  h3 {
    font-size: 15px;
    text-wrap: balance;
  }

  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }

  select{
    height: 3vh;
    font-size: 13px;
  }
  
  #terminos-y-condiciones, #declaracion-jurada{
    font-size: 11px !important;
    align-items: end !important;
  }

  .submit{
    width: 25%;
    height: 5vh;
  }
}
</style>